html,
body {
  scroll-behavior: smooth;
}

img {
  user-select: none;
}

hot-menu a,
hot-content-menu a {
  text-decoration: none;
}

@media (min-width: 1200px) {
  .hot-container {
    max-width: 1000px;
  }
}

@media (min-width: 1700px) {
  .hot-container {
    max-width: 1000px;
  }
}
